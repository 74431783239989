const shopInfo = {
  facebook:
    "https://www.facebook.com/people/Sacred-Art-Saratoga/100064005256986/",
  instagram: "https://www.instagram.com/sacredarttattoo_saratoga",
  yelp: "https://www.yelp.com/biz/sas-tattoo-honolulu-2",
  address: "339 Saratoga Rd, Honolulu, HI 96813",
  phone: "(808) 240-1245",
  title: "Sacred Art Tattoo on Saratoga",
  about:
    "We've been around for a while. We know our stuff, and we love to share it with you!",
  about2:
    "We've been in business since 1992, and we're proud of that. In that time, we've seen a lot of changes to the industry—and we're happy to say that we were always on the cutting edge of those changes. Whether it was our use of new materials or techniques, or just our general love of art, we're always looking for ways to push boundaries and make sure you get nothing but the best from us.",
  about3:
    "All of our appointments are made by phone after you've had a chance to look around our website! Give us a call today or stop by—we'd love to meet you!",
  email: "sales@sacredarthawaii.com",
  hours: "Sun-Sat: 10 AM - Midnight",
  map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7435.505072142892!2d-157.83114!3d21.281261!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c006de8ce894903%3A0x19da527cc880c99!2sSacred%20Art%20Tattoo%20-%20Saratoga!5e0!3m2!1sen!2sus!4v1679367534128!5m2!1sen!2sus",
  mapURL: "https://goo.gl/maps/YDAGticvqVL8GMki9",
  contact: "Saratoga",
};

export default shopInfo;
