import turtles from "../assets/images/turtles.png";
import { Helmet } from "react-helmet";

function Piercing() {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Saratoga | Piercing</title>
      </Helmet>
      <div>
        <section id="piercing" className="coffee_area mt-60 mb-30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_title text-center pb-30">
                  <h4 className="title">Piercing</h4>
                  <img className="turtles" alt="turtles" src={turtles} />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3 className="mb-20">PRICES</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $70
                      <br />
                      Conch: $80
                      <br />
                      Industrial: $100
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $80
                      <br />
                      Helix / Anti-Helix: $60
                      <br />
                      Snug: $70
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Nostril: $60
                      <br />
                      Eyebrow: $80
                      <br />
                      Tongue: $100
                      <br />
                      Septum: $100
                      <br />
                      Labret: $60
                      <br />
                      Lip: $80 / $100 for 2
                      <br />
                      Medusa: $80
                      <br />
                      Monroe: $80
                      <br />
                      Ashley: $80
                      <br />
                      Smiley: $80
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $80
                      <br />
                      Nipple: $60 / $100 for set
                      <br />
                      VCH: $120 (2Bit Only)
                      <br />
                      Christina: $200 (2Bit Only)
                      <br />
                      Dermals: $100 / $160 for set of 2 (2Bit Only)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Piercing;
