import turtles from "../assets/images/turtles.png";
import LocationCard from "./LocationCard";
import { Helmet } from "react-helmet";
import locationsList from "./LocationsArray";

function Locations() {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Saratoga | Locations</title>
      </Helmet>
      <section id="locations" className="container mt-60 mb-60">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section_title text-center pb-30">
              <h4 className="title">Our Locations</h4>
              <img className="turtles" alt="turtles" src={turtles} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {locationsList.map((location) => {
            return (
              <LocationCard
                key={location.description}
                title={location.title}
                pic={location.pic}
                description={location.description}
                link={location.link}
                border={location.border}
              />
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Locations;
